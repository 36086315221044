import RouterService from 'src/services/router.service'
import type { RouteName, RouteParamsMap } from 'src/types/router'
import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import type { LocationQuery } from 'vue-router'
import { useRoute } from 'vue-router'

export function useRouteQuery<T extends LocationQuery>() {
  const route = useRoute()
  return computed(() => route.query as T)
}

export function useRouteQueryParam<T extends string>(param: MaybeRefOrGetter<T | undefined>) {
  const params = useRouteQuery()

  return computed(() => {
    const paramValue = toValue(param)

    if (!paramValue) return undefined

    const value = paramValue in params.value ? params.value[paramValue] : undefined

    if (!value) return undefined

    return (typeof value === 'string' ? value : value[0]) ?? undefined
  })
}

export function useRouteParams<T extends RouteName>(routeName: `${T}`) {
  const route = useRoute()

  return computed(() => {
    return RouterService.getParams(routeName, route) as RouteParamsMap[T] | undefined
  })
}
