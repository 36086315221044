<template>
  <swiper
    class="breadcrumbs-slider"
    :modules="[FreeMode]"
    slides-per-view="auto"
    :free-mode="true"
    :prevent-clicks="$q.platform.has.touch"
    :prevent-clicks-propagation="false"
    :touch-start-prevent-default="false"
  >
    <swiper-slide class="breadcrumbs-slider__item">
      <app-link
        class="breadcrumbs-slider__home"
        :icon="iconHouse"
        :to="RouterService.getRouteLocation('main', { locale })"
        color="theme"
      />
    </swiper-slide>

    <swiper-slide
      v-for="(item, index) in breadcrumbs"
      :key="item.id"
      class="breadcrumbs-slider__item"
    >
      <app-link
        v-if="index + 1 < breadcrumbs.length"
        :to="item.routeLocation"
        class="breadcrumbs-slider__link text-body2"
        color="theme"
      >
        {{ item.name }}
      </app-link>

      <template v-else>
        <span class="text-body2 text-muted">{{ item.name }}</span>
      </template>
    </swiper-slide>
  </swiper>
</template>

<script lang="ts" setup>
import iconHouse from 'assets/icons/light/house.svg?raw'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Breadcrumbs } from 'src/types/breadcrumbs'
import RouterService from 'src/services/router.service'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useMeta } from 'quasar'
import { useI18n } from 'src/composables/useI18n'

export interface Props {
  breadcrumbs: Breadcrumbs
}

const props = defineProps<Props>()

const router = useRouter()

const { t, locale } = useI18n()

const shema = computed(() => {
  const items = [
    {
      name: t('translate.mainPage'),
      item: RouterService.getUrl(
        router.resolve(RouterService.getRouteLocation('main', { locale: locale.value }))
      )
    },
    ...props.breadcrumbs.map(item => ({
      name: item.name,
      item: RouterService.getUrl(router.resolve(item.routeLocation))
    }))
  ]

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': items.map(({ name, item }, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      name,
      item
    }))
  }
})

useMeta(() => ({
  script: {
    breadcrumbsShema: {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(shema.value)
    }
  }
}))
</script>

<style lang="scss" scoped>
.breadcrumbs-slider {
  overflow: visible;
  &:deep(.swiper-wrapper) {
    align-items: center;
  }
  &__item {
    font-weight: 400;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    width: auto;
    &:not(:first-child) {
      padding-left: var(--sm);
      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        transition: background-color var(--trs-1);
        background-color: var(--theme-color-muted);
        margin-right: var(--sm);
      }
    }
  }
}
</style>

<style lang="scss">
@import 'swiper/css';
@import 'swiper/css/free-mode';
</style>
